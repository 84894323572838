import { ROUTE_PATH } from '@/constants';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  FormControl,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import bitraceLogo from '@/assets/bitrace-logo.png';

interface IFormInput {
  email: string;
  password: string;
}

const LoginForm: React.FunctionComponent = () => {
  const [showPassword, setShowPassword] = React.useState<boolean>();

  const theme = useTheme();
  const isSmallMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  const navigate = useNavigate();

  const defaultValues = {
    email: 'rain@gmail.com',
    password: 'rain1989',
  } satisfies IFormInput;

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
    setError,
  } = useForm<IFormInput>({ defaultValues, mode: 'onChange' });

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    console.log(data);

    if (
      isValid &&
      data?.email === 'rain@gmail.com' &&
      data?.password === 'rain1989'
    ) {
      navigate(ROUTE_PATH.INIT, { replace: true });
    } else if (data?.email !== 'rain@gmail.com') {
      setError('email', {
        type: 'manual',
        message: 'This email is not registered',
      });
    } else {
      setError('password', {
        type: 'manual',
        message: 'Incorrect password',
      });
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Box sx={{ mb: { xs: 10, sm: 15 } }}>
        {/* <BitraceLogo /> */}
        <img src={bitraceLogo} alt="" width={48} height={48} />
      </Box>
      <Box
        fontWeight={500}
        sx={{
          fontSize: { xs: '24px', sm: '32px' },
          lineHeight: { xs: '36px', sm: '48px' },
        }}
        mb={5}
      >
        Log in to your account
      </Box>
      <Stack spacing={2} direction="column" useFlexGap>
        <Controller
          name="email"
          control={control}
          rules={{
            required: 'Email is required',
            pattern: {
              value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
              message: 'Enter a valid email address',
            },
          }}
          render={({ field }) => (
            <FormControl>
              <Box fontWeight={500}>Business email</Box>
              <TextField
                {...field}
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email ? errors.email.message : ''}
                margin="normal"
                fullWidth
              />
            </FormControl>
          )}
        />

        <Controller
          name="password"
          control={control}
          rules={{
            required: 'Password is required',
            minLength: {
              value: 6,
              message: 'Password must be at least 6 characters long',
            },
          }}
          render={({ field }) => (
            <FormControl>
              <Box fontWeight={500}>Password</Box>
              <TextField
                {...field}
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: showPassword ? (
                    <VisibilityOutlined
                      cursor="pointer"
                      onClick={(): void => {
                        setShowPassword(false);
                      }}
                    />
                  ) : (
                    <VisibilityOffOutlined
                      cursor="pointer"
                      onClick={(): void => {
                        setShowPassword(true);
                      }}
                    />
                  ),
                }}
                variant="outlined"
                error={!!errors.password}
                helperText={errors.password ? errors.password.message : ''}
                margin="normal"
                fullWidth
              />
            </FormControl>
          )}
        />
      </Stack>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        sx={{ width: '151px', mt: { xs: 5, sm: 10 } }}
        disabled={!isValid}
      >
        Log in
      </Button>
    </form>
  );
};

export default LoginForm;
