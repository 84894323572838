import { RequestParams } from '@/types';
import { generateRequest } from '@/services/config/swr';

const apiPath = {
  getUserInfo: 'get /api/v1/me/profile',
  updateUserInfo: 'put /api/v1/me/profile',
  getApplications: 'get /api/v1/applications',
};

export type apiPathKeys = keyof typeof apiPath;

export const apiRequests: Record<
  apiPathKeys,
  (options?: Omit<RequestParams, 'url'>) => Promise<unknown>
> = generateRequest(apiPath);

export default apiPath;
