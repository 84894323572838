import { Link, Navigate, RouteObject } from 'react-router-dom';
import React from 'react';
import { ROUTE_PATH } from '@/constants';
import HomePage from '@/pages/Home';
import Login from '@/pages/Login';
import NotFound from '@/pages/NotFound';
import InitialPage from '@/pages/InitialPage';
import { Card } from '@mui/material';

export type NestedRouteObject = RouteObject & {
  children?: NestedRouteObject[];
  icon?: React.ReactNode;
  title: string;
  menuVisible?: boolean;
  unAuth?: boolean;
};

const routes: NestedRouteObject[] = [
  // {
  //   path: ROUTE_PATH.INIT,
  //   //element: <Navigate to={ROUTE_PATH.HOME} />,
  //   element: <HomePage />,
  //   title: 'Init',
  //   menuVisible: false,
  // },
  {
    // path: ROUTE_PATH.HOME,
    path: ROUTE_PATH.INIT,
    element: <HomePage />,
    title: 'WorkBench',
    icon: <i className="iconfont icon-home " style={{ fontSize: '24px' }} />,
    menuVisible: true,
  },
  // {
  //   path: ROUTE_PATH.ADMIN,
  //   icon: (
  //     <i className="iconfont icon-admin-key " style={{ fontSize: '24px' }} />
  //   ),
  //   element: <Card sx={{ height: '100%' }}>ADMIN</Card>,
  //   title: 'Admin',
  //   menuVisible: true,
  // },
  {
    path: ROUTE_PATH.CALL_BACK,
    element: <InitialPage />,
    title: 'Callback',
    menuVisible: false,
  },
  {
    path: ROUTE_PATH.LOGIN,
    element: <Login />,
    title: 'Login',
    menuVisible: false,
    unAuth: true,
  },
  {
    path: '*',
    element: <NotFound />,
    title: 'Not Found',
    menuVisible: false,
    unAuth: true,
  },
];

export function flattenRoutes(
  routes: NestedRouteObject[]
): NestedRouteObject[] {
  const flatRoutes: NestedRouteObject[] = [];

  const flatten = (
    routes: NestedRouteObject[],
    parentPath: string = ''
  ): void => {
    routes.forEach((route) => {
      const fullPath = route?.path?.startsWith(parentPath)
        ? route.path
        : `${parentPath}${route.path}`;
      flatRoutes.push({ ...route, path: fullPath });

      if (route.children) {
        flatten(route.children, fullPath);
      }
    });
  };

  flatten(routes);

  return flatRoutes;
}

// export function restoreRoutes(
//   flatRoutes: NestedRouteObject[]
// ): NestedRouteObject[] {
//   const routeMap: Record<string, NestedRouteObject> = {};

//   flatRoutes.forEach((route) => {
//     if (route.path) {
//       routeMap[route.path] = { ...route, children: [] } as NestedRouteObject;
//     }
//   });

//   const routes: NestedRouteObject[] = [];

//   flatRoutes.forEach((route) => {
//     const parentPath = route.path?.split('/').slice(0, -1).join('/');
//     if (parentPath && route.path) {
//       routeMap[parentPath].children?.push(routeMap[route.path]);
//     } else if (!parentPath && route.path) {
//       routes.push(routeMap[route.path]);
//     }
//   });

//   return routes;
// }

export default routes;
