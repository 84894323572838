import {
  Box,
  CircularProgress,
  SxProps,
  Theme,
  alpha,
  useTheme,
} from '@mui/material';
import React from 'react';

interface ISpinProps {
  spinning: boolean;
  tip?: string;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  circularSize?: number;
}

const Spin: React.FC<ISpinProps> = (props) => {
  const theme = useTheme();
  const {
    spinning,
    tip,
    children = null,
    sx = {},
    circularSize = 20,
  } = props || {};
  return spinning ? (
    <Box
      sx={Object.assign(
        {
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          // backgroundColor: alpha(theme.palette.background.default, 0.8),
          gap: 2,
        },
        sx
      )}
    >
      <CircularProgress size={circularSize} />
      {tip && <Box sx={{ color: 'primary.main' }}>{tip}</Box>}
    </Box>
  ) : (
    children
  );
};
Spin.displayName = 'Spin';
export default Spin;
