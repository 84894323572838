import { Box, Typography } from '@mui/material';
import i18n from '@/i18n';
import React from 'react';
import DataEmpty from '@/assets/data-empty.svg';

export default function EmptyData({
  visible,
  img = DataEmpty,
  title,
  subTitle = i18n.t('empty_data'),
  extra,
  style = {},
}: {
  visible: boolean;
  img?: string;
  title?: string | React.ReactNode;
  subTitle?: string | React.ReactNode;
  style?: React.CSSProperties;
  extra?: React.ReactNode;
}) {
  return visible ? (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={2}
      sx={style}
    >
      <Box component="img" src={img} width="80px" height="80px"></Box>
      {title && <Typography variant="h5">{title}</Typography>}
      {subTitle && <Typography variant="body2">{subTitle}</Typography>}
      {extra}
    </Box>
  ) : null;
}
