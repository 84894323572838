import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import '@/styles/index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import './i18n';

import './utils/disable-console';

import { AuthProvider } from 'react-oidc-context';
import { oidcConfig } from '@/constants';

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);
