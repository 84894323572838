import {
  Avatar,
  Box,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  PopoverProps,
  Stack,
  Theme,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { NavigateNext, NavigateBefore } from '@mui/icons-material';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { ThemeMode } from '@/types';
import { styled } from '@mui/system';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAuth } from 'react-oidc-context';
import { useEffect } from 'react';
import { useTriggerRequest } from '@/services';
import apiPath from '@/services/api';
import useUserInfo from '@/hooks/useUserInfo';

interface IAccountPopoverProps extends PopoverProps {
  themeMode: ThemeMode;
  setThemeMode: React.Dispatch<React.SetStateAction<ThemeMode>>;
  anchorEl: Element | null;
  onClose: () => void;
  open: boolean;
}

interface IMenuItemProps {
  label: string;
  icon?: React.ReactNode;
  subMenu?: IMenuItemProps[];
  value?: string;
  handleClick?: () => void;
  selected?: boolean;
  color?: string;
}

const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
  fontSize: '12px',
  minHeight: 0,
  marginBottom: 0,
  paddingLeft: '16px',
  paddingRight: '4px',
  borderRadius: '999px',

  '& .MuiTypography-root': {
    fontSize: '12px',
  },

  '& .MuiListItemIcon-root': {
    color: theme.palette.grey[200],
  },
}));

const AccountPopover: React.FunctionComponent<IAccountPopoverProps> = ({
  themeMode,
  setThemeMode,
  anchorEl,
  onClose,
  open,
  ...reset
}) => {
  const { i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const currentLanguage = i18n.language || 'en';

  const auth = useAuth();

  const { trigger } = useTriggerRequest([apiPath.updateUserInfo]);

  const { mutate } = useUserInfo();

  const handleLanguageChange = async (language: string) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set('locale', language);

    navigate({ search: newSearchParams.toString() }, { replace: true });
    i18n.changeLanguage(language);
    onClose();
    await trigger({ locale: language });
    await mutate();
  };

  const handleThemeChange = (theme: string) => {
    setThemeMode(theme as ThemeMode);
    onClose();
  };

  const navigate = useNavigate();

  const menuConfig: IMenuItemProps[] = [
    {
      label: 'Language',
      icon: <NavigateNext />,
      subMenu: [
        {
          label: 'English',
          value: 'en',
          selected: currentLanguage === 'en',
          handleClick: () => handleLanguageChange('en'),
        },
        {
          label: '简体中文',
          value: 'zh',
          selected: currentLanguage === 'zh',
          handleClick: () => handleLanguageChange('zh'),
        },
        {
          label: '繁體中文',
          value: 'zh-HK',
          selected: currentLanguage === 'zh-HK',
          handleClick: () => handleLanguageChange('zh-HK'),
        },
      ],
    },
    {
      label: 'Theme',
      icon: <NavigateNext />,
      subMenu: [
        {
          label: 'Light',
          value: 'light',
          selected: themeMode === 'light',
          handleClick: () => handleThemeChange('light'),
        },
        {
          label: 'Dark',
          value: 'dark',
          selected: themeMode === 'dark',
          handleClick: () => handleThemeChange('dark'),
        },
        // {
        //   label: 'System',
        //   value: 'system',
        //   selected: themeMode === 'system',
        //   handleClick: () => handleThemeChange('system'),
        // },
      ],
    },
    {
      label: 'Sign out',
      color: 'error.main',
      handleClick: () => {
        console.log('Sign out');
        //navigate(ROUTE_PATH.LOGIN);
        auth.signoutRedirect();
        localStorage.setItem('bitrace_user', '{}');
      },
    },
  ];
  const [renderLevel, setRenderLevel] = React.useState<1 | 2>(1);
  const [renderSecondKey, setRenderSecondKey] = React.useState<string>('');
  const [username, setUsername] = React.useState('');
  const [email, setEmail] = React.useState('');

  // const useInfo=useUserInfo();

  useEffect(() => {
    if (auth.isAuthenticated) {
      if (auth.user?.profile?.preferred_username) {
        setUsername(auth.user.profile.preferred_username);
      }

      if (auth.user?.profile?.email) {
        setEmail(auth.user.profile.email);
      }
    }
  }, [auth]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      slotProps={{
        paper: {
          sx: { width: '204px', mt: '-16px', ml: '-8px' },
        },
      }}
      onClose={onClose}
      open={open}
      {...reset}
    >
      <Box sx={{ py: 3, pl: 3 }} display={'flex'} gap={1}>
        <Avatar
          // alt={useInfo?.profile?.preferred_username ?? ''}
          alt={username ?? ''}
          sx={{ bgcolor: 'primary.grey[50]', width: 40, height: 40 }}
        />
        <Box>
          {/* <Typography variant="body1">{useInfo?.profile?.preferred_username ?? ''}</Typography>
          <Typography variant="body2">{useInfo?.profile?.email ?? ''}</Typography> */}
          <Typography variant="body1">{username ?? ''}</Typography>
          <Typography variant="body2">{email ?? ''}</Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ p: 1 }}>
        {renderLevel === 1 && (
          <Stack direction="column" gap={1}>
            {menuConfig.map((item) => (
              <CustomMenuItem
                key={item.label}
                onClick={() => {
                  if (!isEmpty(item?.subMenu)) {
                    setRenderSecondKey(item?.label as string);
                    return setRenderLevel(2);
                  }
                  if (item?.handleClick instanceof Function) {
                    item?.handleClick();
                  }
                }}
              >
                <ListItemText sx={{ color: item?.color }}>
                  {item.label}
                </ListItemText>
                {item?.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              </CustomMenuItem>
            ))}
          </Stack>
        )}
        {renderLevel === 2 && (
          <Stack direction="column" gap={1}>
            <CustomMenuItem
              sx={{ pl: '4px' }}
              onClick={() => {
                setRenderLevel(1);
              }}
            >
              <ListItemIcon>
                <NavigateBefore />
              </ListItemIcon>
              <ListItemText>
                {
                  menuConfig.find((item) => item.label === renderSecondKey)
                    ?.label
                }
              </ListItemText>
            </CustomMenuItem>
            {menuConfig
              .find((item) => item.label === renderSecondKey)
              ?.subMenu?.map((item) => (
                <CustomMenuItem
                  selected={item?.selected || false}
                  key={item.label}
                  onClick={() => {
                    if (item?.handleClick instanceof Function) {
                      item?.handleClick();
                    }
                  }}
                >
                  <ListItemText>{item.label}</ListItemText>
                </CustomMenuItem>
              ))}
          </Stack>
        )}
      </Box>
    </Popover>
  );
};

export default AccountPopover;
