import * as React from 'react';
import {
  Box,
  IconButton,
  useMediaQuery,
  useTheme,
  Avatar,
} from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { ThemeMode } from '@/types';
import bitraceLogo from '@/assets/bitrace-logo.png';
import useAnchorEl from '@/hooks/useAnchorEl';
import AccountPopover from '../AccountPopover';
import AppsIcon from '@mui/icons-material/Apps';
import AppsPopover from '../AppsPopover';
import { useTriggerRequest } from '@/services';
import apiPath from '@/services/api';
import LoadingButton from '../LoadingButton';
import { useAuth } from 'react-oidc-context';
import { isEmpty } from 'lodash-es';
import MenuIcon from '@mui/icons-material/Menu';

interface HeaderProps {
  themeMode: ThemeMode;
  setThemeMode: React.Dispatch<React.SetStateAction<ThemeMode>>;
  toggleDrawer?: () => void;
}

function Header({
  themeMode,
  setThemeMode,
  toggleDrawer = () => {},
}: HeaderProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const { user } = useAuth();

  // if (!isSmallScreen) {
  //   return null;
  // }

  const {
    anchorRef: appsRef,
    handleClose: handleCloseApps,
    handleOpen: handleOpenApps,
    open: appsOpen,
  } = useAnchorEl<HTMLButtonElement>();

  const {
    anchorRef: accountRef,
    handleClose: handleCloseAccount,
    handleOpen: handleOpenAccount,
    open: accountOpen,
  } = useAnchorEl<HTMLButtonElement>();

  return (
    <AppBar
      component="nav"
      position="fixed"
      sx={{
        boxShadow: 'none',
        bgcolor: 'background.body',
        backgroundImage: 'none',
        padding: 0,
        color: 'text.primary',

        zIndex: 1201,
      }}
    >
      <Toolbar
        variant="regular"
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexShrink: 0,
          maxHeight: '40px',
          paddingLeft: '16px !important',
          paddingRight: '16px !important',
        })}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <IconButton
            onClick={() => toggleDrawer()}
            sx={{
              display: isSmallScreen ? 'block' : 'none',
              cursor: 'pointer',
              maxHeight: '40px',
              maxWidth: '40px',
            }}
          >
            <MenuIcon />
          </IconButton>
          <Avatar src={bitraceLogo} alt="" sx={{ width: 30, height: 30 }} />
          <Box sx={{ fontWeight: 500 }}>Bitrace Portal</Box>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
          {/* <LoadingButton
            variant="contained"
            loading={isMutating}
            onClick={() => {
              trigger({ email: 'rain@gmail.com', password: '123123' });
            }}
          >
            Test API
          </LoadingButton> */}
          {!isEmpty(user) && (
            <IconButton
              ref={appsRef}
              sx={{ cursor: 'pointer' }}
              onClick={handleOpenApps}
            >
              <AppsIcon />
            </IconButton>
          )}
          <Box
            ref={accountRef}
            onClick={handleOpenAccount}
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              gap: 0.5,
            }}
          >
            <IconButton sx={{ cursor: 'pointer' }}>
              <Avatar
                alt="Rain"
                sx={{ bgcolor: 'primary.main', width: 24, height: 24 }}
              />
            </IconButton>
            {/* <Box sx={{ fontWeight: 500 }}>{username ?? 'Anonymous'}</Box> */}
          </Box>
        </Box>
      </Toolbar>
      {appsOpen && (
        <AppsPopover
          anchorEl={appsRef.current}
          onClose={handleCloseApps}
          open={appsOpen}
          sx={{ '& .MuiPaper-root': { borderRadius: '12px' } }}
        />
      )}
      {accountOpen && (
        <AccountPopover
          themeMode={themeMode}
          setThemeMode={setThemeMode}
          anchorEl={accountRef.current}
          onClose={handleCloseAccount}
          open={accountOpen}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          slotProps={{
            paper: { sx: { width: '238px', mt: '28px' } },
          }}
          sx={{ '& .MuiPaper-root': { padding: 0, borderRadius: '12px' } }}
        />
      )}
    </AppBar>
  );
}

export default Header;
