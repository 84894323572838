import * as React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider as Provider } from '@mui/x-date-pickers/LocalizationProvider';
import { enUS, zhCN, zhHK } from '@mui/x-date-pickers/locales';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-hk';
import 'dayjs/locale/en';

export interface LocalizationProviderProps {
  children: React.ReactNode;
}

export default function LocalizationProvider({
  children,
}: LocalizationProviderProps): React.JSX.Element {
  const { i18n } = useTranslation();
  const { curLocale, adapterLocale } = React.useMemo(() => {
    switch (i18n.language) {
      case 'en':
        return { curLocale: enUS, adapterLocale: 'en' };
      case 'zh':
        return { curLocale: zhCN, adapterLocale: 'zh-cn' };
      case 'zh-HK':
        return { curLocale: zhHK, adapterLocale: 'zh-hk' };
      default:
        return { curLocale: enUS, adapterLocale: 'en' };
    }
  }, [i18n.language]);
  return (
    <Provider
      dateAdapter={AdapterDayjs}
      adapterLocale={adapterLocale}
      localeText={
        curLocale?.components?.MuiLocalizationProvider?.defaultProps?.localeText
      }
    >
      {children}
    </Provider>
  );
}
