import { HttpResultStatus } from '@/types/enum';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';

export const checkStatus = (status: HttpResultStatus) => {
  let msg = '';
  switch (status) {
    case HttpResultStatus.BAD_REQUEST:
      msg = t('bad_request');
      break;
    case HttpResultStatus.UNAUTHORIZED:
      msg = t('unauthorized_error');
      break;
    case HttpResultStatus.FORBIDDEN:
      msg = t('forbidden_error');
      break;
    case HttpResultStatus.NOT_FOUND:
      msg = t('not_found_error');
      break;
    case HttpResultStatus.METHOD_NOT_ALLOWED:
      msg = t('not_allowed_method_error');
      break;
    case HttpResultStatus.REQUEST_TIMEOUT:
      msg = t('timeout_error');
      break;
    case HttpResultStatus.INTERNAL_SERVER_ERROR:
      msg = t('internal_server_error');
      break;
    case HttpResultStatus.NOT_IMPLEMENTED:
      msg = t('not_implemented_error');
      break;
    case HttpResultStatus.BAD_GATEWAY:
      msg = t('bad_gateway_error');
      break;
    case HttpResultStatus.SERVICE_UNAVAILABLE:
      msg = t('service_unavailable_error');
      break;
    case HttpResultStatus.GATEWAY_TIMEOUT:
      msg = t('gateway_timeout_error');
      break;
    case HttpResultStatus.HTTP_VERSION_NOT_SUPPORTED:
      msg = t('http_version_not_supported_error');
      break;
    default:
      msg = t('unexpected_error');
      break;
  }
  return enqueueSnackbar(msg, {
    anchorOrigin: {
      horizontal: 'center',
      vertical: 'top',
    },
    variant: 'error',
  });
};
