import EmptyData from '@/components/EmptyData';
import { ROUTE_PATH } from '@/constants';
import { Button } from '@mui/material';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const NotFound: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleGoBack = () => {
    navigate(ROUTE_PATH.INIT, { replace: true });
  };
  return (
    <EmptyData
      visible={true}
      style={{ height: '100vh' }}
      title="404"
      subTitle={t('not_found_desc')}
      extra={
        <Button variant="contained" onClick={handleGoBack}>
          {t('back_home')}
        </Button>
      }
    />
  );
};

export default NotFound;
