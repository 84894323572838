import { useRequest } from '@/services';
import apiPath from '@/services/api';
import useUserInfoStore from '@/store/useUserInfoStore';
import { ResultData, UserInfo } from '@/types';
import { isEmpty } from 'lodash-es';
import { useEffect } from 'react';
import { KeyedMutator } from 'swr';

const useUserInfo = (): {
  data: ResultData<UserInfo> | undefined;
  isLoading: boolean;
  error: Error | unknown;
  mutate: KeyedMutator<ResultData<UserInfo>>;
} => {
  const { updateUserInfo } = useUserInfoStore((state) => state);
  const { data, isLoading, mutate, error } = useRequest<ResultData<UserInfo>>([
    apiPath.getUserInfo,
    { params: {} },
  ]);
  useEffect(() => {
    if (!isEmpty(data) && data?.data) {
      updateUserInfo(data?.data as UserInfo);
    }
  }, [data]);
  return { data, isLoading, error, mutate };
};

export { useUserInfo };
export default useUserInfo;
