import { alpha, PaletteMode, ThemeOptions } from '@mui/material';
import { baseTheme, darkTheme, lightTheme } from './modeThemes';
import {
  black,
  blue,
  borderColors,
  fillColors,
  gray,
  green,
  orange,
  red,
  white,
} from './colors';
import i18n from '@/i18n';

export default function getCustomTheme(mode: PaletteMode): ThemeOptions {
  return {
    ...{ ...(mode === 'dark' ? darkTheme : lightTheme), ...baseTheme },
    components: {
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: '10px',
            boxShadow: `0 4px 16px ${alpha(gray[400], 0.2)}`,
            '& .Mui-selected': {
              color: blue[500],
            },
            ...(theme.palette.mode === 'dark' && {
              '& .Mui-selected': {
                color: white,
              },
              boxShadow: `0 4px 16px ${alpha(blue[700], 0.5)}`,
            }),
          }),
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: ({ theme }) => ({
            padding: '12px 16px',
            textTransform: 'none',
            borderRadius: '10px',
            fontWeight: 500,
            ...(theme.palette.mode === 'dark' && {
              color: gray[400],
              boxShadow: '0 4px 16px rgba(0, 0, 0, 0.5)',
              '&.Mui-selected': { color: blue[500] },
            }),
          }),
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableTouchRipple: true,
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            boxSizing: 'border-box',
            transition: 'all 100ms ease-in',
            '&:focus-visible': {
              outline: `3px solid ${alpha(blue[500], 0.5)}`,
              outlineOffset: '2px',
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            boxSizing: 'border-box',
            boxShadow: 'none',
            borderRadius: '32px',
            textTransform: 'none',
            '&:active': {
              transform: 'scale(0.98)',
            },
            '&.Mui-disabled': {
              color: gray[300],
              backgroundColor: 'transparent',
              cursor: 'not-allowed',
            },
            ...(ownerState.size === 'small' && {
              maxHeight: '32px',
            }),
            ...(ownerState.size === 'medium' && {
              maxHeight: '40px',
            }),
            ...(ownerState.variant === 'contained' &&
              ownerState.color === 'primary' && {
                color: white,
                background: blue[500],
                boxShadow: `inset 0 1px ${alpha(blue[300], 0.4)}`,
                '&:hover': {
                  background: blue[400],
                  backgroundImage: 'none',
                  boxShadow: `0 0 0 1px  ${alpha(blue[300], 0.5)}`,
                },
                '&.Mui-disabled': {
                  color: white,
                  backgroundColor: blue[200],
                  cursor: 'not-allowed',
                },
              }),
            ...(ownerState.variant === 'outlined' && {
              borderColor: borderColors[300],

              color: gray[900],
              '& .MuiButton-icon': {
                color: blue[500],
              },
              '&:hover': {
                borderColor: blue[500],
                backgroundColor: white,
                color: gray[500],
              },
            }),
            ...(ownerState.variant === 'text' && {
              color: gray[900],
              '& .MuiButton-icon': {
                color: blue[500],
              },
              '&:hover': {
                color: gray[500],
              },
            }),
            ...(theme.palette.mode === 'dark' && {
              ...(ownerState.variant === 'outlined' && {
                borderColor: borderColors[400],
                color: gray[100],
                '& .MuiButton-icon': {
                  color: blue[500],
                },
                '&:hover': {
                  borderColor: blue[500],
                  color: white,
                },
              }),
              ...(ownerState.variant === 'text' && {
                color: gray[100],
                '& .MuiButton-icon': {
                  color: blue[500],
                },
                '&:hover': {
                  color: white,
                },
              }),
            }),
          }),
          // root: { borderRadius: '10px', textTransform: 'none' },
          sizeSmall: { padding: '6px 16px' },
          sizeMedium: { padding: '8px 20px' },
          sizeLarge: { padding: '11px 24px' },
          textSizeSmall: { padding: '7px 12px' },
          textSizeMedium: { padding: '9px 16px' },
          textSizeLarge: { padding: '12px 16px' },
        },
      },
      MuiCard: {
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            backgroundColor: white,
            borderRadius: 8,
            // boxShadow: 'none',
            transition: 'background-color, border, 80ms ease-in-out',
            ...(ownerState.variant === 'outlined' && {
              borderColor: 'transparent',
              border: 'none',
              // background: `linear-gradient(to bottom, #FFF, ${gray[50]})`,
              background: white,
              '&:hover': {
                borderColor: blue[300],
                boxShadow: `0 0 24px ${blue[100]}`,
              },
            }),
            ...(theme.palette.mode === 'dark' && {
              backgroundColor: gray[700],
              ...(ownerState.variant === 'outlined' && {
                borderColor: 'transparent',
                border: 'none',
                // background: `linear-gradient(to bottom, ${gray[900]}, ${alpha(
                //   gray[800],
                //   0.5
                // )})`,
                background: gray[800],
                '&:hover': {
                  borderColor: blue[700],
                  boxShadow: `0 0 24px ${blue[800]}`,
                },
              }),
            }),
          }),
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderColor: `${alpha(borderColors[200], 1)}`,
            ...(theme.palette.mode === 'dark' && {
              borderColor: `${alpha(gray[600], 1)}`,
            }),
          }),
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'none',
        },
        styleOverrides: {
          root: ({ theme }) => ({
            color: blue[600],
            fontWeight: 500,
            position: 'relative',
            textDecoration: 'none',
            '&::before': {
              content: '""',
              position: 'absolute',
              width: 0,
              height: '1px',
              bottom: 0,
              left: 0,
              backgroundColor: blue[200],
              opacity: 0.7,
              transition: 'width 0.3s ease, opacity 0.3s ease',
            },
            '&:hover::before': {
              width: '100%',
              opacity: 1,
            },
            ...(theme.palette.mode === 'dark' && {
              color: blue[200],
            }),
          }),
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: ({ theme }) => ({
            borderRadius: '8px',
            marginBottom: '8px',
            color: gray[500],
            // fontWeight: 500,
            '& .MuiListItemIcon-root': {
              minWidth: 0,
            },
            '&:focus': {
              outline: 'none',
              border: 'none',
            },
            '&.Mui-focusVisible': {
              outline: 'none',
            },
            '&.Mui-selected': {
              outline: 'none',
            },
            ...(theme.palette.mode === 'dark' && {
              color: gray[300],
            }),
          }),
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: ({ theme }) => ({
            minWidth: 0,
          }),
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: ({ theme }) => ({
            backgroundImage: 'none',
            backgroundColor: white,
            padding: '8px',

            ...(theme.palette.mode === 'dark' && {
              backgroundColor: gray[800],
              boxShadow: '0px 1px 1px rgba(0, 0, 0, 2)',
            }),
          }),
        },
      },
      MuiSelect: {
        defaultProps: {
          MenuProps: {
            PaperProps: {
              style: {
                marginTop: 4,
              },
            },
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          tooltip: ({ theme }) => ({
            backgroundColor: white,
            // borderRadius: 0,
            color: gray[700],
            boxShadow: '0 1px 5px rgba(0, 0, 0, 0.1)',
            ...(theme.palette.mode === 'dark' && {
              backgroundColor: gray[800],
              color: white,
            }),
          }),
          arrow: ({ theme }) => ({
            color: white,
            ...(theme.palette.mode === 'dark' && {
              color: gray[800],
            }),
          }),
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: ({ theme }) => ({
            boxSizing: 'border-box',
            width: 36,
            height: 24,
            padding: 0,
            transition: 'background-color 100ms ease-in',
            '& .MuiSwitch-switchBase': {
              '&.Mui-checked': {
                transform: 'translateX(13px)',
              },
            },
            '& .MuiSwitch-track': {
              borderRadius: 50,
            },
            '& .MuiSwitch-thumb': {
              boxShadow: '0 0 2px 2px rgba(0, 0, 0, 0.2)',
              backgroundColor: white,
              width: 16,
              height: 16,
              margin: 2,
            },
            ...(theme.palette.mode === 'dark' && {
              width: 36,
              height: 24,
              padding: 0,
              transition: 'background-color 100ms ease-in',
              '& .MuiSwitch-switchBase': {
                '&.Mui-checked': {
                  transform: 'translateX(13px)',
                },
              },
              '& .MuiSwitch-track': {
                borderRadius: 50,
              },
              '& .MuiSwitch-thumb': {
                boxShadow: '0 0 2px 2px rgba(0, 0, 0, 0.2)',
                backgroundColor: white,
                width: 16,
                height: 16,
                margin: 2,
              },
            }),
          }),
          switchBase: {
            height: 24,
            width: 24,
            padding: 0,
            color: white,
            '&.Mui-checked + .MuiSwitch-track': {
              opacity: 1,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
              opacity: 0.5,
              cursor: 'not-allowed',
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: blue[500],
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: blue[500],
              borderWidth: '1px',
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: ({ theme, ownerState }) => ({
            '&.MuiFormControl-root': {
              marginTop: '0px',
              marginBottom: '0px',
            },
            '& .MuiInputBase-input': {
              boxSizing: 'border-box',
              padding: '0 14px',
              '&::placeholder': {
                opacity: 1,
              },
              display: 'flex',
              alignItems: 'center',
              height: ownerState.size === 'small' ? 32 : 40,
              fontSize: ownerState.size === 'small' ? '12px' : '14px',
            },
            '& .MuiOutlinedInput-root': {
              boxSizing: 'border-box',
              height: '100%',
              minHeight: ownerState.size === 'small' ? 32 : 40,
              borderRadius: '36px',
              border: '1px solid',
              borderColor: 'transparent',
              background: gray[50],
              color: gray[500],
              '& .MuiIconButton-root': {
                color: gray[500],
              },
              '& .MuiInputAdornment-root': {
                color: gray[500],
                marginLeft: 0,
              },
              transition: 'border-color 120ms ease-in',
              '& fieldset': {
                border: 'none',
                background: 'transparent',
              },
              '&:hover': {
                borderColor: blue[300],
                background: fillColors[100],
              },
              '&.Mui-focused': {
                borderColor: blue[400],
                background: fillColors[100],
              },
              '&.Mui-error': {
                borderColor: red[500],
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: red[500],
                },
              },
            },
            ...(theme.palette.mode === 'dark' && {
              '& .MuiOutlinedInput-root': {
                boxSizing: 'border-box',
                height: '100%',
                minHeight: ownerState.size === 'small' ? 32 : 40,
                borderRadius: '36px',
                border: '1px solid',
                borderColor: 'transparent',
                background: gray[50],
                color: gray[500],
                '& .MuiIconButton-root': {
                  color: gray[500],
                },
                '& .MuiSvgIcon-root': {
                  color: gray[500],
                },
                '& .MuiInputAdornment-root': {
                  color: gray[500],
                  marginLeft: 0,
                },
                transition: 'border-color 120ms ease-in',
                '& fieldset': {
                  border: 'none',
                  background: 'transparent',
                },
                '&:hover': {
                  borderColor: blue[300],
                  background: fillColors[100],
                },
                '&.Mui-focused': {
                  borderColor: blue[400],
                  background: fillColors[100],
                },

                '&.Mui-error': {
                  borderColor: red[500],
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: red[500],
                  },
                },
              },
            }),
          }),
        },
      },
      MuiTablePagination: {
        defaultProps: {
          labelRowsPerPage: i18n.t('rows_per_page'),
          labelDisplayedRows: ({
            from,
            to,
            count,
          }: {
            from: number;
            to: number;
            count: number;
          }) => {
            return i18n.t('pagination_info', {
              from,
              to,
              count,
            });
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            border: '1px solid red',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid red',
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            color: '#000',
            '&.MuiAlert-standardSuccess': {
              backgroundColor: green[50],
            },
            '&.MuiAlert-standardInfo': {
              backgroundColor: blue[50],
            },
            '&.MuiAlert-standardWarning': {
              backgroundColor: orange[50],
            },
            '&.MuiAlert-standardError': {
              backgroundColor: red[50],
            },

            '& .MuiIconButton-root': {
              color: gray[300],
              '&:hover': {
                backgroundColor: alpha('#000', 0.04),
              },
            },
          },
        },
      },
    },
  };
}
