import Spin from '@/components/Spin';
import instance from '@/services/config/instance';
import React, { ReactNode, useEffect } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import { alpha } from '@mui/material';

export const InitialWrapper: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = React.useState(false);

  const [expStatus, setExpStatus] = React.useState(true);

  const handleSetToken = () => {
    if (auth?.user?.access_token) {
      localStorage.setItem('bitrace_user', JSON.stringify(auth.user));
      setExpStatus(false);
    }
  };

  useEffect(() => {
    if (
      !hasAuthParams() &&
      !auth.isAuthenticated &&
      !auth.activeNavigator &&
      !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      localStorage.setItem('bitrace_user', '{}');
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  React.useEffect(() => {
    if (auth?.user?.access_token) {
      handleSetToken();
    }

    auth.events.addUserLoaded(handleSetToken);

    return () => {
      auth.events.removeUserLoaded(handleSetToken);
    };
  }, [auth, handleSetToken]);

  useEffect(() => {
    const handleTokenExpired = () => {
      setExpStatus(true);
      auth.signinRedirect();
    };

    auth.events.addAccessTokenExpired(handleTokenExpired);
    // const expTime = JSON.parse(
    //   localStorage.getItem('bitrace_user') || '{}'
    // )?.expires_at;
    // if (expTime && expTime * 1000 < Date.now()) {
    //   console.log('expTime', expTime);

    //   setExpStatus(true);
    //   auth.signinRedirect();
    // }

    return () => {
      auth.events.removeAccessTokenExpired(handleTokenExpired);
    };
  }, [auth]);

  return (
    <Spin
      spinning={!auth?.isAuthenticated || expStatus}
      sx={{
        minHeight: '100vh',
        backgroundColor: (theme) =>
          alpha(theme.palette.background.default, 0.8),
      }}
    >
      {children}
    </Spin>
  );
};
