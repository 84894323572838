// disable the console for production env
if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
  console.info = () => {};
  //console.warn = () => {};
  console.debug = () => {};
  console.trace = () => {};
  console.dir = () => {};
  console.dirxml = () => {};
  console.table = () => {};
  console.count = () => {};
  console.countReset = () => {};
  console.group = () => {};
  console.groupCollapsed = () => {};
  console.groupEnd = () => {};
  console.time = () => {};
  console.timeLog = () => {};
  console.timeEnd = () => {};
  console.clear = () => {};
}
