import { useTranslation } from 'react-i18next';

const useAddLocaleSearch = () => {
  const { i18n } = useTranslation();

  const addLocaleSearch = (path: string): string => {
    const currentLanguage = i18n.language;

    const [basePath, search] = path.split('?');
    const searchParams = new URLSearchParams(search);

    searchParams.set('locale', currentLanguage);

    return `${basePath}?${searchParams.toString()}`;
  };

  return { addLocaleSearch };
};

export default useAddLocaleSearch;
