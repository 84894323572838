import { ROUTE_PATH } from '@/constants';
import { enqueueSnackbar } from 'notistack';
import * as React from 'react';

import { useAuth } from 'react-oidc-context';
import { useNavigate, useSearchParams } from 'react-router-dom';

const InitialPage: React.FC = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  React.useEffect(() => {
    if (auth.isAuthenticated) {
      console.log('initial', auth);

      navigate(ROUTE_PATH.INIT, { replace: true });
    }
    // authentication_expired
    if (searchParams?.get('error_description')) {
      enqueueSnackbar(`${searchParams?.get('error_description')}`, {
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
        variant: 'error',
      });
      auth.signoutRedirect();
    }
  }, [auth]);
  return <></>;
};

export default InitialPage;
