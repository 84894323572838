import React, { useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  IconButton,
  useTheme,
  useMediaQuery,
  Button,
} from '@mui/material';
import NestedMenuItem from './NestedMenuItem';
import { useLocation, useNavigate } from 'react-router-dom';
import routes, { NestedRouteObject } from '@/routes';
import { isEmpty } from 'lodash-es';
import { ThemeMode } from '@/types';
import { Search, Menu as MenuIcon } from '@mui/icons-material';
import { useAnchorEl } from '@/hooks/useAnchorEl';
import { styled } from '@mui/system';
import { blue } from '@/styles/theme/colors';
import AccountPopover from '../AccountPopover';
import MenuTooltip from './MenuTooltip';
import bitraceLogo from '@/assets/bitrace-logo.png';

export const CustomListItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'marginBottom',
})<{ marginBottom?: string; fontWeight?: string }>(
  ({ theme, marginBottom }) => ({
    marginBottom: marginBottom || '16px',
    cursor: 'pointer !important',
    height: '36px',
    padding: '0 8px',
    justifyContent: 'center',

    '&:hover': {
      backgroundColor: theme.palette.action.hover,
      borderRadius: '4px 999px 999px 4px',
    },

    '&.Mui-selected': {
      backgroundColor: theme.palette.action.hover,
      color: blue[500],
      borderRadius: '4px 999px 999px 4px',

      '& .MuiListItemIcon-root': {
        color: blue[500],
      },
    },
  })
);

interface SideMenuProps {
  themeMode: ThemeMode;
  setThemeMode: React.Dispatch<React.SetStateAction<ThemeMode>>;
  sideBarOpen?: boolean;
  toggleDrawer?: () => void;
}

const SideMenu: React.FC<SideMenuProps> = ({
  themeMode,
  setThemeMode,
  sideBarOpen,
  toggleDrawer = () => {},
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    anchorRef: accountRef,
    handleClose: handleCloseAccount,
    handleOpen: handleOpenAccount,
    open: accountOpen,
  } = useAnchorEl<HTMLLIElement>();

  const renderTooltipMenu = (menu: NestedRouteObject) => {
    return (
      <List
        component="div"
        sx={{ p: 1, display: 'flex', flexDirection: 'column', gap: 1 }}
      >
        <CustomListItem
          marginBottom="0px"
          key={menu.path}
          onClick={() => {
            navigate(`${menu.path}`);
          }}
          selected={location.pathname === (menu.path as string)}
        >
          <ListItemText primary={menu.title} />
        </CustomListItem>
      </List>
    );
  };

  return (
    <Drawer
      variant={isSmallScreen ? 'temporary' : 'permanent'}
      open={sideBarOpen}
      sx={{
        width: sideBarOpen ? 220 : 72,
        '& .MuiDrawer-paper': {
          width: sideBarOpen ? 220 : 72,
          overflowX: 'hidden',
          transition: 'width 0.3s ease-in-out',
          display: 'flex',
          padding: 0,
          paddingTop: `calc(${theme.mixins.toolbar.minHeight}px + 8px)`,
          border: 'none',
          flexDirection: 'column',
          boxShadow: 'none',
          bgcolor: (theme) => 'background.body',
          // theme.palette.mode === 'dark'
          //   ? 'background.body'
          //   : 'background.default',
        },
      }}
      onClose={() => toggleDrawer()}
    >
      {/* <Button
        startIcon={<Search />}
        variant="contained"
        sx={{
          borderRadius: sideBarOpen ? '36px' : '24px',
          my: 2,
          mx: sideBarOpen ? 1 : 1.5,
          display: 'flex',
          justifyContent: sideBarOpen ? 'flex-start' : 'center',
          minWidth: 0,
          '& .MuiButton-startIcon': {
            mr: sideBarOpen ? 1 : 0,
            ml: sideBarOpen ? -0.5 : 0,
          },
        }}
      >
        {sideBarOpen ? 'Search' : null}
      </Button> */}
      <List
        sx={{
          px: sideBarOpen ? 1 : 2,
          pt: 2,
          flex: 1,
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        {routes
          ?.filter((route) => route.menuVisible)
          .map((route) => {
            if (!isEmpty(route?.children) && route?.children) {
              return (
                <NestedMenuItem
                  key={route.path}
                  icon={route.icon}
                  primary={route.title}
                  to={`${route.path}`}
                  drawerOpen={sideBarOpen}
                  toggleDrawer={toggleDrawer}
                  subMenu={route.children}
                />
              );
            } else {
              return (
                <CustomListItem
                  key={route.path}
                  onClick={() => {
                    navigate(`${route.path}`);
                    if (isSmallScreen) {
                      toggleDrawer();
                    }
                  }}
                  selected={location.pathname === route.path}
                  marginBottom="8px"
                >
                  {route.icon &&
                    (sideBarOpen ? (
                      <ListItemIcon
                        sx={{ minWidth: 0, mr: sideBarOpen ? 1 : 0 }}
                      >
                        {route.icon}
                      </ListItemIcon>
                    ) : (
                      <MenuTooltip title={renderTooltipMenu(route)}>
                        <ListItemIcon
                          sx={{ minWidth: 0, mr: sideBarOpen ? 1 : 0 }}
                        >
                          {route.icon}
                        </ListItemIcon>
                      </MenuTooltip>
                    ))}

                  {sideBarOpen && (
                    <ListItemText
                      primaryTypographyProps={{
                        fontWeight: '500',
                        fontSize: '14px',
                      }}
                      primary={route.title}
                    />
                  )}
                </CustomListItem>
              );
            }
          })}
      </List>

      <Box
        sx={{
          padding: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: sideBarOpen ? 'flex-start' : 'center',
          flexShrink: 0,
        }}
      >
        {/* <IconButton
          sx={{
            cursor: 'pointer',
            height: '40px',
            mb: 1,
            display: { xs: 'none', sm: 'block' },
          }}
          onClick={toggleDrawer}
        >
          <MenuIcon />
        </IconButton> */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            px: sideBarOpen ? 0 : 1,
          }}
        >
          {/* <CustomListItem
            ref={accountRef}
            onClick={handleOpenAccount}
            selected={accountOpen}
            sx={{
              '&.Mui-selected': {
                backgroundColor: theme.palette.action.hover,
                color: theme.palette.text.primary,
                borderRadius: '4px',
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: 0, mr: sideBarOpen ? 1 : 0 }}>
              <img src={bitraceLogo} alt="" width={24} height={24} />
            </ListItemIcon>
            {sideBarOpen && (
              <ListItemText
                primaryTypographyProps={{
                  fontWeight: '500',
                  fontSize: '14px',
                }}
                primary={'Account'}
              />
            )}
            {sideBarOpen && (
              <i
                className="iconfont icon-more"
                style={{ fontSize: '24px', color: theme.palette.grey[200] }}
              />
            )}
          </CustomListItem> */}
        </Box>
      </Box>
      {accountOpen && (
        <AccountPopover
          themeMode={themeMode}
          setThemeMode={setThemeMode}
          anchorEl={accountRef.current}
          onClose={handleCloseAccount}
          open={accountOpen}
        />
      )}
    </Drawer>
  );
};

export default SideMenu;
