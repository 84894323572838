export const red = {
  50: '#FFE7E7',
  100: '#FFB8B8',
  200: '#FF8888',
  300: '#FF7171',
  400: '#FF5959',
  500: '#FF2A2A',
  600: '#E61010',
  700: '#CC0E0E',
  800: '#990B0B',
  900: '#660707',
};

export const orange = {
  50: '#FFF2E7',
  100: '#FFD9B6',
  200: '#FFC086',
  300: '#FFB36E',
  400: '#FFA756',
  500: '#FF8E25',
  600: '#E6740C',
  700: '#CC670A',
  800: '#994D08',
  900: '#663405',
};

export const yellow = {
  50: '#FFFAE7',
  100: '#FFEFB6',
  200: '#FFE586',
  300: '#FFDF6E',
  400: '#FFDA56',
  500: '#FFCF25',
  600: '#E6B60C',
  700: '#CCA20A',
  800: '#997908',
  900: '#665105',
};

export const green = {
  50: '#E7FBEF',
  100: '#B7F4CF',
  200: '#87ECAF',
  300: '#70E8A0',
  400: '#58E490',
  500: '#28DD70',
  600: '#0EC356',
  700: '#0DAE4D',
  800: '#0A823A',
  900: '#065726',
};

export const blue = {
  50: '#E7F2FF',
  100: '#B8D9FE',
  200: '#88BFFD',
  300: '#70B2FC',
  400: '#58A5FC',
  500: '#298CFB',
  600: '#0F72E1',
  700: '#0E66C8',
  800: '#0A4C96',
  900: '#073364',
};

export const purple = {
  50: '#F5E7FF',
  100: '#E0B8FF',
  200: '#CB88FF',
  300: '#C171FF',
  400: '#B659FF',
  500: '#A12AFF',
  600: '#8810E6',
  700: '#790ECC',
  800: '#5B0B99',
  900: '#3C0766',
};

export const gray = {
  50: '#EDEEEF',
  100: '#CACDD0',
  200: '#A7ACB2',
  300: '#959BA2',
  400: '#838B92',
  500: '#606A74',
  600: '#46505A',
  700: '#3E4750',
  800: '#2F353C',
  900: '#1F2428',
};

export const white = '#FFFFFF';

export const black = '#000000';

export const fillColors = {
  100: '#FDFEFF',
  200: '#F2F5F7',
  300: '#E4E7EB',
  400: '#D3D9DE',
};

export const borderColors = {
  100: '#F8F9FA',
  200: '#EDEEEF',
  300: '#CACDD0',
  400: '#838B92',
};
