import { isDev } from '@/utils';
import { WebStorageStateStore, Log } from 'oidc-client-ts';

if (isDev()) {
  Log.setLogger(console);
}

// define OIDC configuration
export const oidcConfig = {
  authority: process.env.REACT_APP_AUTHORITY,
  client_id: process.env.REACT_APP_CLIENT_ID,
  //redirect_uri: `${window.location.origin}${window.location.pathname}`,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI,
  post_logout_redirect_uri: window.location.origin,
  scope: 'openid profile email',
  ui_locals: 'en',
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  monitorSession: true, // this allows cross tab login/logout detection
};
