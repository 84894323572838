import axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from 'axios';
import { checkStatus } from '../helper/checkStatus';

const instance: AxiosInstance = axios.create({
  //   baseURL: process.env.REACT_APP_API_URL,
  baseURL: '/',
  timeout: 1000 * 60 * 3,
  withCredentials: true,
});

instance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const user = JSON.parse(localStorage.getItem('bitrace_user') || '{}');
    console.log('user', user);

    if (user?.access_token) {
      config.headers = config.headers || {};
      config.headers['Authorization'] = `Bearer ${user?.access_token}`;
    }

    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    console.log('error', error);

    const { response } = error;
    if (response) checkStatus(response.status);

    console.error(error);
    // return Promise.reject(error);
  }
);

export default instance;
