import * as React from 'react';
import { PaletteMode, useMediaQuery } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import AutoAwesomeRoundedIcon from '@mui/icons-material/AutoAwesomeRounded';
import getCustomTheme from '@/styles/theme/getTheme';
import { useEffect, useMemo, useState } from 'react';
import { ThemeMode, ThemeModeEnum } from '@/types';
import LocalizationProvider from './LocalizationProvider';
import { useTranslation } from 'react-i18next';
import AppContent from './AppContent';
import GlobalSWRConfig from '@/services/SWRGlobalConfig';
import { SnackbarProvider } from 'notistack';
import { InitialWrapper } from './InitialWrapper';

interface ToggleCustomThemeProps {
  showCustomTheme: boolean;
  toggleCustomTheme: () => void;
}

function ToggleCustomTheme({
  showCustomTheme,
  toggleCustomTheme,
}: ToggleCustomThemeProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24,
      }}
    >
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={showCustomTheme}
        onChange={toggleCustomTheme}
        aria-label="Platform"
        sx={{
          backgroundColor: 'background.paper',
          '& .Mui-selected': {
            pointerEvents: 'none',
          },
        }}
      >
        <ToggleButton value>
          <AutoAwesomeRoundedIcon sx={{ fontSize: '20px', mr: 1 }} />
          Custom theme
        </ToggleButton>
        <ToggleButton value={false}>Material Design</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}

export default function AppLayOut() {
  // const [mode, setMode] = React.useState<PaletteMode>('light');
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);

  const { i18n, t } = useTranslation();

  // Detect system theme preference
  // const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  // State to manage theme mode
  const [themeMode, setThemeMode] = useState<ThemeMode>(() => {
    return (
      (localStorage.getItem('theme_store_mode') as ThemeMode) ||
      ThemeModeEnum.DARK
    );
  });

  // Determine actual theme mode based on preference and system setting
  // const mode = useMemo(() => {
  //   if (themeMode === ThemeModeEnum.SYSTEM) {
  //     return prefersDarkMode ? ThemeModeEnum.DARK : ThemeModeEnum.LIGHT;
  //   }
  //   return themeMode;
  // }, [themeMode, prefersDarkMode]);

  // Create a theme object
  const theme = useMemo(
    () =>
      createTheme({
        ...getCustomTheme(themeMode as PaletteMode),
      }),
    [themeMode, getCustomTheme]
  );

  const isSmallMdScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const root = document.documentElement;

    root.style.overflow = 'hidden';

    localStorage.setItem('theme_store_mode', themeMode);
    root.setAttribute('data-theme', themeMode === 'dark' ? 'dark' : 'light');

    const timer = setTimeout(() => {
      root.style.overflow = '';
    }, 300);

    return () => clearTimeout(timer);
  }, [themeMode]);

  const defaultTheme = createTheme({
    palette: { mode: themeMode as PaletteMode },
  });

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  const [sideBarOpen, setSideBarOpen] = useState(true);

  useEffect(() => {
    if (isSmallMdScreen) {
      setSideBarOpen(false);
    } else {
      setSideBarOpen(true);
    }
  }, [isSmallMdScreen]);

  const toggleSideDrawer = () => {
    setSideBarOpen(!sideBarOpen);
  };

  return (
    <LocalizationProvider>
      <ThemeProvider theme={showCustomTheme ? theme : defaultTheme}>
        <GlobalSWRConfig>
          <CssBaseline />
          <SnackbarProvider
            autoHideDuration={3000}
            disableWindowBlurListener={true}
          />
          <InitialWrapper>
            <AppContent
              themeMode={themeMode}
              setThemeMode={setThemeMode}
              sideBarOpen={sideBarOpen}
              toggleDrawer={toggleSideDrawer}
            />
          </InitialWrapper>
          {/* <ToggleCustomTheme
            showCustomTheme={showCustomTheme}
            toggleCustomTheme={toggleCustomTheme}
          /> */}
        </GlobalSWRConfig>
      </ThemeProvider>
    </LocalizationProvider>
  );
}
