export function isProd() {
  return process.env.NODE_ENV === 'production';
}

export function isDev() {
  return process.env.NODE_ENV === 'development';
}

export const formatLngToI18n = (lng: string) => {
  switch (lng) {
    case 'zh':
    case 'zh-CN':
    case 'zh-Hans':
      return 'zh';
    case 'zh-HK':
    case 'zh-TW':
    case 'zh-Hant':
      return 'zh-HK';
    case 'en':
    case 'en-US':
    case 'en-UK':
    case 'en-AU':
      return 'en';
    default:
      return 'en';
  }
};
