import { Tooltip, TooltipProps } from '@mui/material';
import * as React from 'react';
import { useRef } from 'react';

interface IMenuTooltipProps extends TooltipProps {
  children: React.ReactElement;
  title: string | React.ReactNode;
}

const MenuTooltip: React.FunctionComponent<IMenuTooltipProps> = ({
  children,
  title,
  ...reset
}) => {
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleTooltipOpen = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    timeoutRef.current = setTimeout(() => {
      setTooltipOpen(false);
    }, 100);
  };
  return (
    <div
      onMouseEnter={handleTooltipOpen}
      onMouseLeave={handleTooltipClose}
      style={{ display: 'inline-flex' }}
    >
      <Tooltip
        title={title}
        arrow
        placement="right"
        open={tooltipOpen}
        disableHoverListener
        componentsProps={{ tooltip: { sx: { p: 0 } } }}
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 16],
              },
            },
          ],
        }}
        {...reset}
      >
        {children}
      </Tooltip>
    </div>
  );
};

export default MenuTooltip;
