import Header from '@/components/Header';
import SideMenu from '@/components/SideMenu';
import { ThemeMode } from '@/types';
import { Box, useTheme } from '@mui/material';
import * as React from 'react';
import {
  matchPath,
  useLocation,
  useRoutes,
  useSearchParams,
} from 'react-router-dom';
import routes, { flattenRoutes } from '@/routes';
import { useTranslation } from 'react-i18next';
import useUserInfo from '@/hooks/useUserInfo';
import Spin from '@/components/Spin';
import { formatLngToI18n } from '@/utils';

interface IAppContentProps {
  themeMode: ThemeMode;
  setThemeMode: React.Dispatch<React.SetStateAction<ThemeMode>>;
  sideBarOpen?: boolean;
  toggleDrawer?: () => void;
}

const AppContent: React.FunctionComponent<IAppContentProps> = (props) => {
  const { themeMode, setThemeMode, sideBarOpen, toggleDrawer } = props;
  const location = useLocation();
  const {
    mixins: { toolbar },
  } = useTheme();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const isInitialized = React.useRef(false);

  const { data: userInfo, isLoading: userLoading } = useUserInfo();

  const currentRoute = flattenRoutes(routes)?.find((route) =>
    matchPath(route.path as string, location.pathname)
  );

  React.useEffect(() => {
    if (searchParams.get('locale')) {
      i18n.changeLanguage(searchParams.get('locale') as string);
    } else if (userInfo?.data?.locale && !isInitialized.current) {
      i18n.changeLanguage(formatLngToI18n(userInfo?.data?.locale));
      isInitialized.current = true;
    }
  }, [i18n, userInfo, searchParams]);

  const isUnAuthRoute = currentRoute ? currentRoute.unAuth : false;

  const appRouter = useRoutes(routes);

  return (
    <Box
      sx={{
        display: 'flex',
        bgcolor: 'background.body',
        // pt: { xs: isUnAuthRoute ? 0 : 8, sm: 0 },
        pt: isUnAuthRoute ? 0 : `calc(${toolbar?.minHeight}px + 8px)`,
      }}
    >
      {!isUnAuthRoute && (
        <>
          <Header
            themeMode={themeMode}
            setThemeMode={setThemeMode}
            toggleDrawer={toggleDrawer}
          />
          <SideMenu
            themeMode={themeMode}
            setThemeMode={setThemeMode}
            sideBarOpen={sideBarOpen}
            toggleDrawer={toggleDrawer}
          />
        </>
      )}
      <Box
        flexGrow={1}
        component="main"
        sx={{
          // mt: '64px',
          flexGrow: 1,
          overflow: 'auto',
          minHeight: `calc(100vh - ${toolbar?.minHeight}px - 8px)`,
          padding: isUnAuthRoute ? 0 : 2,
        }}
      >
        <Spin spinning={userLoading} tip={t('loading_tip')}>
          {appRouter}
        </Spin>
      </Box>
    </Box>
  );
};

export default AppContent;
