import { alpha, PaletteMode } from '@mui/material';
import {
  blue,
  borderColors,
  fillColors,
  gray,
  green,
  orange,
  white,
} from './colors';
import { red } from '@mui/material/colors';

export const lightTheme = {
  palette: {
    mode: 'light' as PaletteMode,
    primary: {
      main: blue[500],
      contrastText: blue[50],
    },
    secondary: {
      main: gray[500],
    },
    warning: {
      main: orange[500],
    },
    error: {
      main: red[500],
    },
    success: {
      main: green[500],
    },
    grey: {
      ...gray,
    },
    // divider: borderColors[200],
    background: {
      default: white,
      paper: white,
      body: '#edeeef',
      active: fillColors[200],
    },
    text: {
      primary: gray[800],
      secondary: gray[600],
    },
  },
};

export const darkTheme = {
  palette: {
    mode: 'dark' as PaletteMode,
    primary: {
      main: blue[500],
      contrastText: blue[100],
    },
    secondary: {
      main: gray[500],
    },
    warning: {
      main: orange[500],
    },
    error: {
      main: red[500],
    },
    success: {
      main: green[500],
    },
    grey: {
      ...gray,
    },
    // divider: borderColors[200],
    background: {
      default: gray[700],
      paper: gray[700],
      body: gray[900],
      active: gray[700],
    },
    text: {
      primary: white,
      secondary: gray[400],
    },
  },
};

export const baseTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: [
      // '-apple-system',
      // 'BlinkMacSystemFont',
      // '"Segoe UI"',
      'Roboto',
      // '"Helvetica Neue"',
      // 'Arial',
      // '"Noto Sans"',
      // 'sans-serif',
      // '"Apple Color Emoji"',
      // '"Segoe UI Emoji"',
      // '"Segoe UI Symbol"',
      // '"Noto Color Emoji"',
      // 'Georgia',
    ].join(','),
    h1: {
      fontSize: 60,
      fontWeight: 600,
      lineHeight: 78 / 70,
      letterSpacing: -0.2,
    },
    h2: {
      fontSize: 48,
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: 42,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: 36,
      fontWeight: 500,
      lineHeight: 1.5,
    },
    h5: {
      fontSize: 20,
      fontWeight: 600,
    },
    h6: {
      fontSize: 18,
    },
    subtitle1: {
      fontSize: 18,
    },
    subtitle2: {
      fontSize: 16,
    },
    caption: {
      fontWeight: 400,
      fontSize: 12,
    },
  },
};
