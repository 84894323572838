// import { applications } from '@/constants';
import { useRequest } from '@/services';
import apiPath from '@/services/api';
import { IApplication, ResultData } from '@/types';
import {
  alpha,
  Avatar,
  Box,
  Popover,
  PopoverProps,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash-es';
import * as React from 'react';
import bitraceLogo from '@/assets/bitrace-logo.png';
import Spin from '../Spin';
import { useTranslation } from 'react-i18next';
import useAddLocaleSearch from '@/hooks/useAddLocaleSearch';

interface IAppsPopoverProps extends PopoverProps {
  anchorEl: Element | null;
  onClose: () => void;
  open: boolean;
}

const AppsPopover: React.FunctionComponent<IAppsPopoverProps> = (props) => {
  const { anchorEl, onClose, open, ...reset } = props;
  const { t } = useTranslation();
  const [mainApps, setMainApps] = React.useState<Array<IApplication>>([]);
  const [allApps, setAllApps] = React.useState<Array<IApplication>>([]);
  const { addLocaleSearch } = useAddLocaleSearch();

  const { data, isLoading } = useRequest<ResultData<IApplication[]>>([
    apiPath.getApplications,
  ]);

  React.useEffect(() => {
    if (!isEmpty(data)) {
      // group data by auth_required boolean flag and sort by name
      const allApps = (data?.data || [])
        .sort((a, b) => a.name.localeCompare(b.name))
        ?.filter(
          (ele) =>
            ele?.enabled &&
            ele?.frontend_client_id !== process.env.REACT_APP_CLIENT_ID
        );
      const mainApps = (data?.data || [])
        .filter(
          (item) =>
            item.auth_required &&
            item.frontend_client_id !== process.env.REACT_APP_CLIENT_ID
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      const otherApps = (data?.data || [])
        .filter((item) => !item.auth_required)
        .sort((a, b) => a.name.localeCompare(b.name));

      setMainApps(mainApps);
      setAllApps(allApps);
    }
  }, [data]);
  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      slotProps={{
        paper: {
          sx: {
            width: '346px',
            maxHeight: '500px',
            overflowY: 'auto',
            scrollbarWidth: 'thin',
            scrollbarColor: (theme) => {
              return theme.palette.mode === 'dark'
                ? `${theme.palette.grey[600]} transparent`
                : `${theme.palette.grey[100]} transparent`;
            },
            mt: '20px',
          },
        },
      }}
      onClose={onClose}
      open={open}
      {...reset}
    >
      <Spin
        spinning={isLoading}
        tip={t('loading_tip')}
        sx={{ minHeight: '150px' }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'grid',
            gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
            gap: 1,
          }}
        >
          {allApps.map((item, index) => (
            <Box
              component={'a'}
              key={index}
              sx={{
                bgcolor: 'transparent',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                flexDirection: 'column',
                color: 'inherit',
                textDecoration: 'none',
                py: 2,
                '&:hover': {
                  bgcolor: (theme) =>
                    theme.palette.mode === 'dark'
                      ? alpha(theme.palette.background.default, 0.8)
                      : '#F5F5F5',
                  // : alpha(theme.palette.grey[50], 0.8),
                },
                transition: 'background-color 100ms ease-in-out',
              }}
              href={item.frontend_url ? addLocaleSearch(item.frontend_url) : ''}
              target="_blank"
              rel="noreferrer"
            >
              <Avatar
                src={item.app_image_uri || bitraceLogo}
                alt={item.name}
                sx={{ mb: 0.5, width: 30, height: 30 }}
              />
              <Typography
                variant="body2"
                sx={{
                  width: '90%',
                  textAlign: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {item.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </Spin>
    </Popover>
  );
};

export default AppsPopover;
