import bitraceLogo from '@/assets/bitrace-logo.png';
import Spin from '@/components/Spin';
import { applications } from '@/constants';
import useAddLocaleSearch from '@/hooks/useAddLocaleSearch';
import { useRequest } from '@/services';
import apiPath from '@/services/api';
import { ResultData } from '@/types';
import { IApplication } from '@/types/response';
import { NavigateNext } from '@mui/icons-material';
import {
  Card,
  useTheme,
  Typography,
  Stack,
  Box,
  Avatar,
  Divider,
  Button,
  Link,
  Container,
} from '@mui/material';
import { isEmpty } from 'lodash-es';

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';

const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const auth = useAuth();
  const { addLocaleSearch } = useAddLocaleSearch();

  const [allApps, setAllApps] = React.useState<Array<IApplication>>([]);
  const [mainApps, setMainApps] = React.useState<Array<IApplication>>([]);
  const [otherApps, setOtherApps] = React.useState<Array<IApplication>>([]);

  const [username, setUsername] = React.useState('');
  const [email, setEmail] = React.useState('');

  // const useInfo=useUserInfo();

  useEffect(() => {
    if (auth.isAuthenticated) {
      if (auth.user?.profile?.preferred_username) {
        setUsername(auth.user.profile.preferred_username);
      }

      if (auth.user?.profile?.email) {
        setEmail(auth.user.profile.email);
      }
    }
  }, [auth]);

  const { data, isLoading } = useRequest<ResultData<IApplication[]>>([
    apiPath.getApplications,
  ]);

  React.useEffect(() => {
    if (!isEmpty(data)) {
      // group data by auth_required boolean flag and sort by name
      const allApps = (data?.data || [])
        .filter(
          (item) =>
            item.enabled &&
            item.frontend_client_id !== process.env.REACT_APP_CLIENT_ID
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      const mainApps = (data?.data || [])
        .filter(
          (item) =>
            item.auth_required &&
            item.enabled &&
            item.frontend_client_id !== process.env.REACT_APP_CLIENT_ID
        )
        .sort((a, b) => a.name.localeCompare(b.name));
      const otherApps = (data?.data || [])
        .filter((item) => !item.auth_required && item.enabled)
        .sort((a, b) => a.name.localeCompare(b.name));

      setAllApps(allApps);
      setMainApps(mainApps);
      setOtherApps(otherApps);
    }
  }, [data]);

  return (
    <Container maxWidth="lg" sx={{ height: '100%' }}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'center',
          // alignItems: 'center',
        }}
      >
        <div className="text-[30px] md:text-[42px] lg:text-[64px]    font-bold mb-[64px] lg:mb-[128px]">
          {`Welcome，${username ?? ''}`}
        </div>
        <Spin spinning={isLoading} tip={t('loading_tip')} sx={{ flex: 1 }}>
          <Box
            sx={{
              width: '100%',
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
              gap: {
                xs: 2,
                sm: 3,
                md: 4,
              },
            }}
          >
            {allApps.map((item, index) => (
              <Card
                key={index}
                variant="outlined"
                sx={{
                  p: 2,
                  pb: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Avatar
                      src={item.app_image_uri || bitraceLogo}
                      alt={item.name}
                      sx={{ mr: 1 }}
                    />
                    <Typography variant="h5">{item.name}</Typography>
                  </Box>
                  <Typography variant="body2" sx={{ flex: 1 }}>
                    {item.description}
                  </Typography>
                </Box>
                <Box>
                  <Divider sx={{ mt: 2, mb: 1 }} />
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                      gap: 2,
                    }}
                  >
                    <Button
                      variant="text"
                      endIcon={<NavigateNext />}
                      type="link"
                      href={
                        item.frontend_url
                          ? addLocaleSearch(item.frontend_url)
                          : ''
                      }
                      target="_blank"
                    >
                      {t('view')}
                    </Button>
                  </Box>
                </Box>
              </Card>
            ))}
          </Box>
        </Spin>
      </Box>
    </Container>
  );
};

export default HomePage;
