import React from 'react';
import { SWRConfig } from 'swr';
import fetcher from '@/services/config/swr';

type GlobalSWRConfigProps = React.PropsWithChildren<unknown>;

const GlobalSWRConfig: React.FC<GlobalSWRConfigProps> = ({ children }) => (
  <SWRConfig
    value={{
      fetcher,
      revalidateOnFocus: false,
      dedupingInterval: 1000 * 3,
      shouldRetryOnError: true,
      errorRetryCount: 3,
      errorRetryInterval: 1000 * 5,
    }}
  >
    {children}
  </SWRConfig>
);

export default GlobalSWRConfig;
