import { UserInfo } from '@/types';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export interface UserInfoState {
  data: UserInfo | undefined;
  updateUserInfo: (data: UserInfo) => void;
}

const useUserInfoStore = create<UserInfoState>()(
  persist(
    (set) => ({
      data: undefined,
      updateUserInfo: (data) => set({ data: data }),
    }),
    {
      name: 'bitrace-user-info',
      storage: createJSONStorage(() => localStorage),
    }
  )
);

export default useUserInfoStore;
