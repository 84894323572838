import { AxiosRequestConfig } from 'axios';
import instance from './instance';
import type { RequestParams } from '@/types';
import useSWRMutation, {
  SWRMutationConfiguration,
  SWRMutationResponse,
} from 'swr/mutation';
import useSWR, { Key, SWRConfiguration } from 'swr';
import { isEmpty } from 'lodash-es';

/**
 * @description: global fetcher for swr
 */
const fetcher = async ([url, options = {}]: [
  string,
  Omit<RequestParams, 'url'>,
]) => {
  const urlList = url.split(' ');
  let method: string, path: string;
  if (urlList?.length === 1) {
    method = 'GET';
    path = urlList[0];
  } else {
    method = urlList[0];
    path = urlList[1];
  }
  const { params, data, config } = options;
  const axiosConfig: AxiosRequestConfig = {
    ...config,
    params,
  };

  switch (method?.toUpperCase()) {
    case 'GET':
      return (await instance.get(path, axiosConfig))?.data;
    case 'POST':
      return (await instance.post(path, data, axiosConfig))?.data;
    case 'PUT':
      return (await instance.put(path, data, axiosConfig))?.data;
    case 'DELETE':
      return (await instance.delete(path, axiosConfig))?.data;
    default:
      throw new Error('Unsupported request method');
  }
};

/**
 * @description: global mock fetcher for swr
 */
const mockFetcher = async (
  [url, options = {}]: [string, Omit<RequestParams, 'url'>],
  mockData?: unknown
) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockData);
    }, 3000);
  });
};

/**
 * @description: generate ApiPath value to Promise<unknown>
 * @use  ApiRequests.login({data: {email: 'test@gmail.com', password: '123456'}})
 */
export const generateRequest = (
  apiPath: Record<string, string>
): Record<
  keyof typeof apiPath,
  (options?: Omit<RequestParams, 'url'>) => Promise<unknown>
> => {
  const apiRecords: Record<
    keyof typeof apiPath,
    (options?: Omit<RequestParams, 'url'>) => Promise<unknown>
  > = {};
  Object.keys(apiPath).forEach((key) => {
    apiRecords[key] = (options?: Omit<RequestParams, 'url'>) => {
      return fetcher([apiPath[key], options || {}]);
    };
  });
  return apiRecords;
};

/**
 * @description: useRequest as useSWR
 * @use  useRequest(['POST /api/users',{params?:{},data?:{}}],swrOptions)
 */
export const useRequest = <Data = unknown, Error = unknown>(
  key: [string, Omit<RequestParams, 'url'>?] | null,
  config?: SWRConfiguration<Data, Error>,
  mockData?: Data
) => {
  if (key === null) {
    return useSWR<Data, Error>(null, null, config);
  }
  const [url, options] = key;
  const {
    data: swrData,
    error,
    isLoading,
    mutate,
  } = useSWR<Data, Error>(
    key,
    (arg) => {
      return isEmpty(mockData)
        ? fetcher([url, options || {}])
        : mockFetcher([url, options || {}], mockData);
    },
    config
  );

  return {
    data: swrData,
    error: error,
    isLoading: isLoading,
    mutate,
  };
};

/**
 * @description: useTriggerRequest as useSWRMutation
 * @use  useTriggerRequest(['POST /api/users',{params?:{},data?:{}}],swrOptions)
 */
export const useTriggerRequest = <
  Data = unknown,
  Error = unknown,
  Arg = unknown,
>(
  key: [string, Omit<RequestParams, 'url'>?] | null,
  config?: SWRMutationConfiguration<Data, Error, Key, Arg>,
  mockData?: Data
) => {
  if (key === null) {
    return {
      data: undefined,
      error: undefined,
      trigger: () => Promise.resolve(),
      isMutating: false,
    } as SWRMutationResponse<Data, Error, Key, Arg>;
  }

  const [url, options = {}] = key;
  const [method, _] = url.split(' ');

  return useSWRMutation<Data, Error, Key, Arg>(
    key,
    (triggerKey: string, triggerOptions: { arg: Arg }) => {
      if (!isEmpty(mockData)) {
        return mockFetcher(
          [
            url,
            {
              ...(options || {}),
              params: triggerOptions?.arg as unknown as Record<string, unknown>,
            },
          ],
          mockData
        );
      }

      if (method?.toUpperCase() === 'GET') {
        return fetcher([
          url,
          {
            ...(options || {}),
            params: triggerOptions?.arg as unknown as Record<string, unknown>,
          },
        ]);
      } else {
        return fetcher([
          url,
          {
            ...(options || {}),
            data: triggerOptions?.arg as unknown as Record<string, unknown>,
          },
        ]);
      }
    },
    config
  );
};

export default fetcher;
