import React, { ReactNode, useState } from 'react';
import {
  List,
  ListItemIcon,
  ListItemText,
  Collapse,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import styled from '@emotion/styled';
import { useLocation, useNavigate } from 'react-router-dom';
import { CustomListItem } from './index';
import { NestedRouteObject } from '@/routes';
import MenuTooltip from './MenuTooltip';

interface NestedMenuItemProps {
  icon?: ReactNode;
  primary: string;
  to: string;
  children?: ReactNode;
  subMenu?: NestedRouteObject[];
  toggleDrawer?: () => void;
  drawerOpen?: boolean;
}

const RotatingIcon = styled('span')<{ open: boolean }>(({ open }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'transform 0.3s ease',
  transform: open ? 'rotate(0deg)' : 'rotate(-90deg)',
}));

const NestedMenuItem: React.FC<NestedMenuItemProps> = ({
  icon,
  primary,
  to,
  children,
  subMenu,
  toggleDrawer = () => {},
  drawerOpen,
}) => {
  const [open, setOpen] = useState(true);
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const handleClick = () => {
    setOpen(!open);
  };

  const isActive = location.pathname.startsWith(to);

  const renderTooltipMenu = () => {
    return (
      <List
        component="div"
        sx={{ p: 1, display: 'flex', flexDirection: 'column', gap: 1 }}
      >
        {subMenu &&
          subMenu.map((item) => (
            <CustomListItem
              marginBottom="0px"
              key={item.path}
              onClick={() => {
                navigate(`${item.path}`);
              }}
              selected={location.pathname?.startsWith(item.path as string)}
            >
              <ListItemText primary={item.title} />
            </CustomListItem>
          ))}
      </List>
    );
  };

  return (
    <>
      <CustomListItem
        onClick={handleClick}
        selected={isActive}
        sx={{
          '&.Mui-selected': {
            backgroundColor: theme.palette.action.hover,
          },
        }}
      >
        {icon &&
          (drawerOpen ? (
            <ListItemIcon sx={{ minWidth: 0, mr: drawerOpen ? 1 : 0 }}>
              {icon}
            </ListItemIcon>
          ) : (
            <MenuTooltip title={renderTooltipMenu()}>
              <ListItemIcon sx={{ minWidth: 0, mr: drawerOpen ? 1 : 0 }}>
                {icon}
              </ListItemIcon>
            </MenuTooltip>
          ))}
        {drawerOpen && (
          <ListItemText
            primaryTypographyProps={{ fontWeight: '500', fontSize: '14px' }}
            primary={primary}
          />
        )}
        {drawerOpen && (
          <RotatingIcon open={open}>
            <ExpandMore />
          </RotatingIcon>
        )}
      </CustomListItem>
      <Collapse in={open && drawerOpen} timeout="auto" unmountOnExit>
        <List
          component="div"
          sx={{
            ml: '20px',
            px: '14px',
            py: 0,
            mb: 2,
            borderLeft: `1px solid ${theme.palette.grey[100]}`,
          }}
        >
          {subMenu &&
            subMenu.map((item) => (
              <CustomListItem
                marginBottom="12px"
                key={item.path}
                onClick={() => {
                  navigate(`${item.path}`);
                  if (isSmallScreen) {
                    toggleDrawer();
                  }
                }}
                selected={location.pathname?.startsWith(item.path as string)}
                sx={{
                  position: 'relative',
                  '&.Mui-selected': {
                    '&:before': {
                      content: '""',
                      position: 'absolute',
                      left: '-16px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                      width: '3px',
                      height: '50%',
                      backgroundColor: theme.palette.primary.main,
                      borderRadius: '2px',
                    },
                  },
                }}
              >
                <ListItemText
                  primary={item.title}
                  primaryTypographyProps={{ fontSize: '14px' }}
                />
              </CustomListItem>
            ))}
        </List>
      </Collapse>
    </>
  );
};

export default NestedMenuItem;
