import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import React from 'react';
import LoginForm from './LoginForm';
import ImageIcon from '@mui/icons-material/Image';
import WorkIcon from '@mui/icons-material/Work';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';

const Login: React.FC = () => {
  const loginDescList = [
    {
      icon: <ImageIcon />,
      title: 'Risk Data Perception',
      desc: 'Open domestic and international bank accounts in minutes.',
    },
    {
      icon: <WorkIcon />,
      title: 'Risk Event Management',
      desc: 'Open domestic and international bank accounts in minutes.',
    },
    {
      icon: <BeachAccessIcon />,
      title: 'Risk Dynamic Monitoring',
      desc: 'Open domestic and international bank accounts in minutes.',
    },
  ];
  return (
    <>
      <Container
        maxWidth={false}
        sx={{ maxWidth: 1200, display: { xs: 'none', sm: 'flex' } }}
      >
        <Box display={'flex'} flex={1} justifyContent={'center'}>
          <Card
            sx={{
              p: 2,
              flex: 1,
              minHeight: '100vh',
              borderRadius: '8px 0 0 8px',
              display: 'flex',
              pb: 20,
            }}
          >
            <Box
              flex={1}
              sx={{
                px: 6,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              <LoginForm />
            </Box>
          </Card>
          <Card
            sx={{
              p: 2,
              flex: 1,
              minHeight: '100vh',
              borderRadius: '0 8px 8px 0',
            }}
          >
            <Box
              flex={1}
              sx={{
                px: 6,
                pb: 10,
                height: '100%',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <List
                sx={{
                  width: '100%',
                  bgcolor: 'transparent',
                  pt: 30,
                  '& .MuiListItem-root': { alignItems: 'flex-start' },
                  '& .MuiAvatar-root': { backgroundColor: '#D3D9DE' },
                  '& .MuiListItemText-root': { my: 0 },
                  '& .MuiListItemText-primary': {
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '24px',
                    mb: 1.5,
                  },
                  '& .MuiListItemText-secondary': {
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: 400,
                    color: 'grey.400',
                  },
                }}
              >
                {loginDescList.map((item, index) => (
                  <ListItem
                    key={index}
                    sx={{ mb: index !== loginDescList?.length - 1 ? 4 : 0 }}
                  >
                    <ListItemAvatar>
                      <Avatar
                        sx={{
                          width: '72px',
                          height: '72px',
                          mr: 5,
                          '& .MuiSvgIcon-root': {
                            width: '36px',
                            height: '36px',
                          },
                        }}
                      >
                        {item.icon}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={item.title} secondary={item.desc} />
                  </ListItem>
                ))}
              </List>

              <Box
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  transform: 'translateX(-50%)',
                  color: 'grey.400',
                  textAlign: 'center',
                }}
              >
                © Detrust 2024. All rights reserved.
              </Box>
            </Box>
          </Card>
        </Box>
      </Container>
      <Card
        sx={{
          p: 2,
          minHeight: '100vh',
          borderRadius: 0,
          display: { xs: 'flex', sm: 'none' },
        }}
      >
        <Box
          flex={1}
          sx={{
            px: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <LoginForm />
        </Box>
      </Card>
    </>
  );
};

export default Login;
